import axios from "axios";
import store from "@/store";
import AuthService from "@/services/authService";
import i18n from "@/i18n";
import router from "@/router/index";
import { TokenData } from "@/models/token";
import NotificationService from "@/services/notificationService";
import ApiService from "@/services/apiService";
import tenantService from "@/services/tenantService";

const refreshToken = async (tokenData: TokenData): Promise<TokenData> => {
  const resp = await AuthService.refreshToken(tokenData);
  await store.dispatch("session/setRefreshedToken", resp);
  return resp;
};

const _notAuthorizedRoutes = (): string[] => {
  return Object.values(ApiService.auth).map((item) => item());
};

export default function start(): void {
  axios.interceptors.request.use(
    async (config) => {
      config.timeout = 30000;
      config.headers["Content-Type"] = "application/json";
      config.headers.Accept = "application/json";
      if (config.url?.split("/").includes("geocode")) {
        return config;
      }
      config.headers["Accept-Language"] = i18n.locale;
      config.headers["tenant_code"] = tenantService.getTenantCode();
      config.headers["Label"] = tenantService.getTenant();
      config.headers["Product"] = "Console";
      config.headers["Timezone-Offset"] =
        store.getters["session/timezone"].offset;
      if (config.url && !_notAuthorizedRoutes().includes(config.url)) {
        let tokenData = AuthService.getTokenData();
        if (tokenData?.token) {
          if (AuthService.isTokenExpired(tokenData)) {
            try {
              tokenData = await refreshToken(tokenData);
            } catch (err) {
              AuthService.logout();
            }
          }
          config.headers["Authorization"] = `Bearer ${tokenData.token}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!error.response) {
        const errorString = error.toString();
        if ("Error: Network Error" === errorString) {
          NotificationService.warning(
            i18n.t("noAvailableUrl.serviceUnavailable") as string
          );
        }
        if ("Error: timeout of 30000ms exceeded" === errorString) {
          NotificationService.warning(
            i18n.t("common.requestTimeout") as string
          );
        }
        return Promise.reject(error);
      }
      if (error.response.status === 401) {
        store.dispatch("session/logout");
        router.push("/auth/signin").catch(() => {
          // empty
        });
      }
      return Promise.reject(error);
    }
  );
}
